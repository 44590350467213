<template>
  <div class="bg-snow-white fc-b noto-sans">
    <!-- Header-->
    <header class="py-5" style="background: #3a567e;">
      <div class="container px-4 px-lg-5 my-5">
        <div class="text-center text-white">
          <h1 class="display-4 fw-bolder nanum-square-bold">스펙북 (Spec Book)</h1>
          <p class="lead fw-normal text-white-50 mb-0 nanum-square-bold">
            건축에 사용되는 자재를 조회하고 해당 자재에 대한 상세 스펙을 볼 수 있습니다
          </p>
        </div>
      </div>
    </header>

    <section class="py-2" style="background: #6ea3ebd9;">
      <div class="container row fc-w" style="font-weight: 400; font-size: 1rem;">
        <ul class="display-base mb-0 my-1" style="list-style-type: none;">
          <li class="px-2">
            <a class="fs-13 spec-menu" :href="'#/spec-shop?menu='">전체</a>
          </li>
          <li v-for="(menu, index) of specMenuOpts" :key="index" class="px-2">
            <a class="fs-13 spec-menu" :href="'#/spec-shop?menu='+menu.value">{{ menu.text }}</a>
          </li>
        </ul>
      </div>
    </section>

    <section class="py-2 bg-snow-white mt-3">
      <div class="container px-4 px-lg-5" style="text-align: -webkit-center;">
        <div class="display-base px-2 py-2" style="border: solid 1px #052943; border-radius: 1rem; max-width: 600px; min-height: 30px;">
          <!-- 좌측 -->
          <div class="px-1 pr-3">
            <a href="#/spec-shop">
              <div class="img-cube-logo" style="width: 34px; height: 34px;"></div>
            </a>
          </div>

          <!-- 중간 -->
          <div style="width: -webkit-fill-available;">
            <!-- 엔터검색 추가 -->
            <input class="search-input" v-model="searchText" type="text" maxlength="100" placeholder="자재명을 입력해주세요." @keydown.enter="itemSearch" />
          </div>

          <!-- 우측 -->
          <div class="pr-1 pl-3">
            <div class="icon-search" style="width: 25px; height: 25px; cursor: pointer;" @click="itemSearch"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="py-2 bg-snow-white">
      <div class="container px-4 px-lg-5">
        <div class="display-base mt-2 mb-3" style="border-bottom: solid 1px rgb(5 41 67 / 50%); justify-content: space-between;">
          <div> 
            <h3>검색결과</h3>
          </div>
          <div class="display-base mb-1" v-if="isLogin && userInfo.user.userClass === 1">
            <div class="mr-1">
              <button class="btn-howcost-warning px-3" id="show-btn" @click="$bvModal.show('multiple-register-modal')">대량등록</button>
            </div>
            <div>
              <b-button class="px-3" href="#/spec-shop-create" variant="howcost">게시물 등록</b-button>
            </div>
          </div>
        </div>

        <!-- <ul class="display-base my-3 px-2" style="flex-wrap: wrap;"> -->
        <ul class="display-base my-3 px-0" style="flex-wrap: wrap">
          <li v-for="(item, index) in items" :key="index" class="ts-title px-2 my-3" style="font-size: 12pt; align-items: left;">
            <a class="fc-b" style="text-decoration: none;" href="/">
              <div class="" style="width: 226px; height: 226px; background: #c8c8c8; border: solid 1px #dbdbdb;">
                <img v-if="item.prdImg" :src="$imgUrl + item.prdImg" alt="상품이미지" style="width: 225px; height: 225px;">
                <img v-else src="../../../public/images/no-image(x300).png" alt="상품이미지" style="width: 225px; height: 225px;">
              </div>
              <div class="my-1" style="width: 226px;">
                <p class="txt" style="font-weight: 500;" :title="item.prdName">{{ item.prdName }}</p>
                <p class="txt fc-g fs-10" style="" :title="item.unit">{{ item.unit }}</p>
                <p class="txt fc-g fs-10">{{ item.brand }}</p>

                <div v-if="!item.isDiscount">
                  <span>{{ commify(item.price) }}원</span>
                </div>

                <div v-else>
                  <span>
                    <b-badge class="mr-1" variant="danger">할인</b-badge>
                    <span>{{ commify(item.price - item.discountPrice) }}원</span>
                    <span class="fc-g ml-1 fs-10" style="text-decoration: line-through;">{{ commify(item.price) }}원</span>
                  </span>
                </div>
              </div>
            </a>
          </li>
        </ul>

        <div class="my-4 fc-b">
          <b-pagination
            class="mb-0"
            v-model="currentPage" 
            :per-page="perPage" 
            :total-rows="rows"
            hide-ellipsis
            align="center"
            size="lg"
            prev-text="<"
            next-text=">"
            first-text="|◀"
            last-text="▶|"
          >
          </b-pagination>
        </div>

      </div>
    </section>

    <!-- 대용량 등록 모달 -->
    <b-modal 
      id="multiple-register-modal"
      dialog-class="spec-register-dialog"
      hide-footer
    >
      <template #modal-title>
        스펙북앤샵 - 상품 대량등록
      </template>
      <div>
        <div class="mt-1">
          <b-button variant="success" :href="$webUrl + '/files/스펙북앤샵_대량등록_양식.xlsx'">
            <b-icon class="mr-1" icon="file-earmark-excel-fill" />양식 다운로드
          </b-button>

          <b-button
            class="ml-1" 
            variant="info"
            v-b-popover="'양식 내 \'ex)\' 5번째 행은 그대로 놔두고, 6번째 행(No.1) 부터 입력해주시면 됩니다. ' + ' 상품이미지나 상품 상세내용은 등록 후 수정해주세요.' + ' \'규격\'은 콤마(,)로 구분됩니다.'"
            title="도움말"
          >
            <b-icon class="mr-1" icon="question-circle-fill" />도움말
          </b-button>

          <p class="mt-1">양식을 다운로드 받아서 내용을 채우신 후 업로드 해주세요.</p>
        </div>

        <div class="mt-3">
          <b-form-file 
            v-model="excelFile"
            :multiple=false
            placeholder="대량등록 할 Excel 파일을 선택해주세요."
          />
          <p class="ml-1 fc-r fs-9">ⓘ꼭! 정해진 양식으로 업로드 해주셔야 오류없이 업로드가 가능합니다.</p>
        </div>

        <div class="mt-4 display-base">
          <button class="btn-howcost mr-2" @click="excelFileUpload">상품등록</button>
          <button class="btn-howcost-red ml-2" @click="$bvModal.hide('multiple-register-modal')">취소</button>
        </div>
      </div>
    </b-modal>
    <!-- //대용량 등록 모달 -->
    
  </div>
</template>

<script>
import store from '@/store';
import specBookType from '../../common/specShopType';
import { 
  apiCall,
  apiMultiPart,
  commify
 } from '../../common/utils';

export default {
  name: "SpecShop",
  data() {
    return {
      searchText: '',
      specMenu: '',
      specMenuOpts: specBookType.CategoryOpts,
      userInfo: {},
      isLogin: false,
      items: {},

      currentPage: 1, //현재페이지
      perPage: 20, //한페이지에 보기 개수
      rows: 100, //조회 전체 개수

      excelFile: [],
    }
  },

  watch: {
    '$route.query.menu': 'updateMenu',
    'currentPage': 'specItemRead'
  },

  async created() {
    const token = localStorage.accessToken;
    if (token) {
      this.isLogin = store.dispatch('userStore/REFLASH', token);
      if (this.isLogin) this.setUserInfo;
    }
    // console.log("userInfo", this.userInfo.user);

    // console.log("this.$route.query.menu;", this.$route.query.menu);
    await this.specItemRead();
  },

  mounted() {
  },

  computed: {
    setUserInfo() {
      this.userInfo = store.state.userStore;
    },
  },

  methods: {
    commify(n) { return commify(n) },

    async updateMenu() {
      this.specMenu = this.$route.query.menu;
      // console.log("this.specMenu: ", this.specMenu);
      this.searchText = '';
      this.currentPage = 1;

      await this.specItemRead();
    },
    
    async itemSearch() {
      // console.log("this.searchText: ", this.searchText);
      if (this.searchText.length < 2) {
        return alert('최소 2자 이상 입력해주세요.');
      }
      await this.specItemRead();
    },
    
    async specItemRead() {
      const query = {
        category: this.specMenu,
        q: this.searchText,
        currentPage: this.currentPage,
        perPage: this.perPage
      };
      // console.log("query: ", query);
      
      try {
        const r = await apiCall('GET', `/api/spec-shop/read/item`, query);
        // console.log("items: ", r.result);

        this.rows = r.result.rows;
        this.items = r.result.items;
      
      } catch (error) {
        console.error(error);
        alert("상품 결과를 읽어오지 못했습니다.");
      }
    },

    async excelFileUpload() {
      const formData = new FormData();
      formData.append('excel', this.excelFile);

      try {
        const r = await apiMultiPart(`/api/spec-shop/multiple-create/item`, formData);
        if (r.code === 200) {
          alert( r.result );
          this.$router.go();
        }
      } catch (error) {
        console.error("error =======> ", error);
        alert("오류가 발생하여 등록되지 않았습니다. 양식을 새로 받아서 재시도 해주세요.")
      }
    },

  }
}
</script>
<style>
p {
  margin-bottom: 0;
}
.spec-menu {
  font-weight: 600;
  color: rgb(238, 238, 238);
  text-decoration: none;
}
.spec-menu:hover {
  font-weight: 600;
  color: rgb(212, 212, 212);
  text-decoration: none;
}
.page-link {
  color: black;
}

.spec-register-dialog {
  margin-top: 120px;
}
</style>